import axios from "axios";
import axiosRetry from "axios-retry";
import { curry } from "ramda";
import { getAPIEndpoint } from "../utils/externalRoutes";

try {
  const testKey = "__some_random_key_you_are_not_going_to_use__";
  localStorage.setItem(testKey, testKey);
  localStorage.getItem(testKey);
  localStorage.removeItem(testKey);
} catch (e) {
  window.alert(
    "Your browser does not allow us to log you in. You might be in incognito / private browsing mode."
  );
}

const authToken = localStorage.getItem("authToken");
const headers = authToken
  ? {
      Authorization: `Token ${authToken}`,
    }
  : {};

const instance = axios.create({
  // baseURL: API_ENDPOINT, TODO handle this and resolve with below
  headers,
});

// Set the AUTH token for any request
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token ? `Token ${token}` : "";
  return config;
});

axiosRetry(instance, { retries: 3, retryCondition: axiosRetry.isNetworkError });

const setAuthToken = (authToken) => {
  localStorage.setItem("authToken", authToken);
};

const clearAuthToken = () => {
  localStorage.removeItem("authToken");
};

const API_ENDPOINT = getAPIEndpoint();

const makeUrl = curry((root, path) => (path ? `${root}/${path}` : root));
const apiPath = makeUrl(API_ENDPOINT);

const get = curry((path, config) => instance.get(apiPath(path), config));
const post = curry((path, config) => instance.post(apiPath(path), config));

const randomDelay = () => Math.random() * 1000;
const fake = (data, delay = randomDelay()) =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve({ data }), delay);
  });

export {
  get,
  post,
  fake,
  apiPath,
  setAuthToken,
  clearAuthToken,
  instance as axiosInstance,
  API_ENDPOINT,
};
