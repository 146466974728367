import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import moment from "moment";
import { context as quizContext, actions } from "./QuizContext";
import { context as authContext, actions as authActions } from "../AuthContext";

import endpoints from "../../api/endpoints";
import Quiz from "./Quiz";
import { Loader } from "../Loader";
import { EndScreen } from "../EndScreen";
import WaitingRoom from "./WaitingRoom";

const { quiz } = endpoints;
const quizExists = ({ title }) => title !== null;
const noQuizExists = ({ quiz }) => !quizExists(quiz);
const quizInProgress = ({ quizComplete, quiz, endScreen }) =>
  quizExists(quiz) && !quizComplete && endScreen !== "1";

const getQuizEndpoint = ({
  questions,
  share,
  quizId,
  term,
  lang,
  searchParams,
}) => {
  if (questions) return quiz.getByQuestions(questions);
  if (share) return quiz.getByShareQuestion(share);
  if (quizId && lang) return quiz.get_with_language(quizId, lang);
  if (quizId) return quiz.get({ quizId, searchParams });
  if (term) return quiz.getDynamic(term);
};

const QuizController = ({ endScreen }) => {
  const searchString = window.location.search;
  const { embed: embedView } = queryString.parse(searchString);

  const { state, dispatch } = useContext(quizContext);
  const { state: authState, dispatch: authDispatch } = useContext(authContext);

  const { quizId: quizIdFromParams, lang } = useParams();
  const { term, questions, share } = queryString.parse(window.location.search);

  const history = useHistory();

  const quiz = _.get(state, "quiz", {});
  const { quizComplete } = state;

  const hasQuiz = !!quiz.id || quiz.isShared;
  const { is_public: isPublic } = quiz;
  const { isLoggedIn } = authState;

  const promptLogin =
    hasQuiz &&
    !quiz.isShared &&
    !isPublic &&
    isLoggedIn === false &&
    !quizComplete;

  const hasNotStarted = (startTime) =>
    startTime && moment().isBefore(startTime);

  useEffect(() => {
    if (quizComplete) {
      endpoints.quiz.checkCompletion(state).catch((error) => {
        if (error.message === "Network Error") {
          window.alert(
            "You were not connected to the Internet, so your quiz result may not have fully saved. Just to be sure, please take a screenshot of your score and email it to support@quizzify.zendesk.com or show it to a Quizzify team member."
          );
        }
      });
    }
  }, [quizComplete, state]);

  useEffect(() => {
    if (promptLogin) {
      authDispatch({
        type: authActions.promptLogin,
      });
    } else {
      authDispatch({
        type: authActions.clearPromptLogin,
      });
    }
  }, [promptLogin, authDispatch]);

  useEffect(() => {
    if (!hasQuiz) {
      const searchParams = queryString.parse(searchString);

      getQuizEndpoint({
        quizId: quizIdFromParams,
        term,
        questions,
        share,
        lang,
        searchParams,
      })
        .then((res) => {
          const { deactivated, deactivated_message } = res.data;

          if (deactivated) {
            history.push({
              pathname: "/deactivated",
              state: { message: deactivated_message },
            });
          } else if (res.data.code === 4001) {
            history.push("/get-dynamic/?error=noresults");
          } else {
            dispatch({ type: actions.loadState, data: res.data });
          }
        })
        .catch((err) => {
          console.error(err);
          const status = _.get(err, "response.status");

          if (status === 401) {
            authDispatch({
              type: authActions.promptLogin,
            });
          } else if (status === 403) {
            // Temporary solution for now
            window.alert(
              "You don't have permission to view this quiz. Check your link."
            );
          } else if (status === 404) {
            // Page not found
            // Temporary solution for now
            window.alert("Whoops! This page doesn't exist. Check your link.");
          } else {
            window.alert(
              `We apologize. There was an error loading the quiz. Please contact Help.\n${err}`
            );
          }
        });
    }
  }, [
    quizIdFromParams,
    term,
    questions,
    dispatch,
    history,
    authDispatch,
    hasQuiz,
    share,
    lang,
    searchString,
  ]);

  const enhancedState = {
    ...state,
    embedView,
    endScreen,
  };

  if (noQuizExists(enhancedState)) {
    return <Loader />;
  } else if (
    hasNotStarted(enhancedState?.quiz?.startTime) &&
    !enhancedState?.hideWaitingRoom
  ) {
    return (
      <WaitingRoom
        state={enhancedState}
        onClickPlayEarly={() => dispatch({ type: actions.hideWaitingRoom })}
      />
    );
  } else if (quizInProgress(enhancedState)) {
    return <Quiz state={enhancedState} lang={lang} />;
  } else {
    return <EndScreen state={enhancedState} />;
  }
};

export default QuizController;
