import React, { useContext } from "react";
import { context as authContext } from "../AuthContext";
import { CircularProgress, Container, Typography, Stack } from "@mui/material";
import WalletPasses from "./WalletPasses";
import GetTheSticker from "./GetTheSticker";

const Togo = () => {
  const { state } = useContext(authContext);

  if (!state.isLoggedIn) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      <Container maxWidth="sm" sx={{ pb: 10 }}>
        <Stack sx={{ mt: 5 }} spacing={3}>
          <Typography variant="h1" textAlign="center">
            Take our <em>ER Prevent Consent</em> with you to keep (almost all)
            ER bills under $1,000*.
          </Typography>
          <Typography textAlign="center">
            * Routine ER visits will be &lt;$1000. Procedures, outside consults,
            and scans will cost more but the savings will be even greater.
          </Typography>
          <WalletPasses />
          <GetTheSticker />
        </Stack>
      </Container>
    </div>
  );
};

export default Togo;
