import React, { useContext, useState } from "react";
import {
  Modal,
  Card,
  TextField,
  Button,
  CardContent,
  CardHeader,
  Divider,
  CardActions,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { context } from "../../AuthContext";
import { axiosInstance, API_ENDPOINT } from "../../../api/core";

const AddressDialogueContainer = (props) => {
  const {
    state: { userProfile },
  } = useContext(context);

  if (!userProfile) return null;

  return <AddressDialogue userProfile={userProfile} {...props} />;
};

const AddressDialogue = ({ open, handleClose, userProfile }) => {
  const [submitted, setSubmitted] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userProfile?.first_name,
      lastName: userProfile?.last_name,
    },
  });

  const onSubmit = (data) => {
    axiosInstance
      .post(`${API_ENDPOINT}/er_stickers/request_sticker`, {
        ...data,
      })
      .then((response) => {
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
        window.alert("Failed to request sticker: ", err.message);
        setSubmitted(false);
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxWidth: "100%",
          height: {
            xs: "100%",
            sm: "100%",
            md: "auto",
            lg: "auto",
            xl: "auto",
          },
          maxHeight: {
            md: "100vh",
            lg: "100vh",
            xl: "100vh",
          },
          overflow: {
            xs: "none",
            sm: "none",
            md: "auto",
            lg: "auto",
            xl: "auto",
          },
        }}
      >
        {submitted ? (
          <Typography>
            Your request has been submitted. You will receive your sticker in
            2-3 weeks.
          </Typography>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              height: "100%",
              maxHeight: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardHeader title="Get your free ER Consent sticker" />
            <Divider />
            <CardContent sx={{ overflowY: "scroll", flexGrow: 1 }}>
              <TextField
                label="First Name"
                fullWidth
                margin="normal"
                name="firstName"
                inputRef={register({
                  required: "First Name is required",
                })}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ""}
              />
              <TextField
                label="Last Name"
                fullWidth
                margin="normal"
                name="lastName"
                inputRef={register({
                  required: "Last Name is required",
                })}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : ""}
              />
              <TextField
                label="Street Address"
                fullWidth
                margin="normal"
                name="streetAddress"
                inputRef={register({
                  required: "Street Address is required",
                })}
                error={!!errors.streetAddress}
                helperText={
                  errors.streetAddress ? errors.streetAddress.message : ""
                }
              />
              <TextField
                label="City"
                fullWidth
                margin="normal"
                name="city"
                inputRef={register({ required: "City is required" })}
                error={!!errors.city}
                helperText={errors.city ? errors.city.message : ""}
              />
              <TextField
                label="State"
                fullWidth
                margin="normal"
                name="state"
                inputRef={register({ required: "State is required" })}
                error={!!errors.state}
                helperText={errors.state ? errors.state.message : ""}
              />
              <TextField
                label="Zip Code"
                fullWidth
                margin="normal"
                name="zipCode"
                inputRef={register({
                  required: "Zip Code is required",
                  pattern: { value: /^\d{5}$/, message: "Invalid Zip Code" },
                })}
                error={!!errors.zipCode}
                helperText={errors.zipCode ? errors.zipCode.message : ""}
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>
              <Button onClick={handleClose} variant="outlined">
                Close
              </Button>
            </CardActions>
          </form>
        )}
      </Card>
    </Modal>
  );
};

export default AddressDialogueContainer;
