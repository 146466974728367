import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  CardHeader,
} from "@mui/material";
import AddToAppleWalletButton from "../AddToAppleWalletButton";
import AddToGoogleWalletButton from "../AddToGoogleWalletButton";
import DownloadApplePass from "./DownloadApplePass";
import DownloadGooglePass from "./DownloadGooglePass";

const WalletPasses = () => {
  const [showPassDownload, setShowPassDownload] = useState(false);

  return (
    <>
      {showPassDownload === "apple" && (
        <DownloadApplePass
          open
          handleClose={() => setShowPassDownload(false)}
        />
      )}

      {showPassDownload === "google" && (
        <DownloadGooglePass
          open
          handleClose={() => setShowPassDownload(false)}
        />
      )}

      <Card>
        <CardHeader
          title="Add the ER Prevent Consent to your Apple or Google Wallet"
          subheader="It will ask for your location, and tag local hospitals to pop up when you're close."
        />
        <Divider />
        <CardContent>
          <Typography>
            <ol style={{ margin: 0, paddingLeft: 18 }}>
              <li style={{ marginBottom: 12 }}>
                <Typography>Select Apple or Google Wallet:</Typography>
                <Grid spacing={2} container sx={{ mt: 1, mb: 1 }}>
                  <Grid item>
                    <AddToAppleWalletButton
                      onClick={() => setShowPassDownload("apple")}
                    />
                  </Grid>
                  <Grid item>
                    <AddToGoogleWalletButton
                      onClick={() => setShowPassDownload("google")}
                    />
                  </Grid>
                </Grid>
              </li>
              <li style={{ marginBottom: 12 }}>
                <Typography>
                  It will ask for your location to find the nearest hospitals
                  (the pass will pop up to remind you when you're there).
                </Typography>
              </li>
              <li>
                <Typography>Follow the instructions to save it.</Typography>
              </li>
            </ol>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default WalletPasses;
