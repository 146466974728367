import React from "react";
import {
  Card,
  CardHeader,
  Tooltip,
  Typography,
  CardContent,
  Stack,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import Quiz from "./Quiz";
import Tabs from "../Tabs";
import { Loader } from "../Loader";

const renderTabs = (quizzes, rest) => (
  <Tabs
    tabs={[
      {
        label: "Eligible",
        component: (
          <Stack spacing={2}>
            {quizzes
              ?.filter((q) => q.eligible)
              .map((q, i) => (
                <Quiz key={q.id} quiz={q} latest={i === 0} {...rest} />
              ))}
          </Stack>
        ),
      },
      {
        label: "Bonus",
        component: (
          <Stack spacing={2}>
            {quizzes
              ?.filter((q) => !q.eligible)
              .map((q) => (
                <Quiz key={q.id} quiz={q} {...rest} />
              ))}
          </Stack>
        ),
      },
    ]}
  />
);

const QuizzesPanel = ({ quizzes, rest, showTabs }) => {
  if (!quizzes)
    return (
      <CardContent>
        <Loader />
      </CardContent>
    );
  if (quizzes.length === 0)
    return (
      <CardContent>
        <Typography>
          No Quiz is Available Just Yet, But Please Hang On We'll Have Them for
          You Soon!
        </Typography>
      </CardContent>
    );

  return (
    <>
      {showTabs ? (
        renderTabs(quizzes, rest)
      ) : (
        <Stack spacing={2}>
          {quizzes.map((q, i) => (
            <Quiz key={q.id} quiz={q} latest={i === 0} {...rest} />
          ))}
        </Stack>
      )}
    </>
  );
};

const Quizzes = ({ quizzes, ...rest }) => {
  const hasBonus = quizzes?.some((q) => !q.eligible);

  return (
    <Card
      sx={(theme) => ({
        backgroundColor: `${theme.palette.primary.main}12`,
      })}
      raised
    >
      <CardHeader
        sx={{ backgroundColor: "white" }}
        title={
          <Stack spacing={1} direction="row" justifyContent="center">
            <Typography variant="h6">
              {!quizzes
                ? "We Are Getting Your Latest and Greatest Quizzes!"
                : "Your Quizzes"}
            </Typography>

            {hasBonus && (
              <Tooltip title="Eligible quizzes are formally part of your program, and apply towards any incentives offered. Bonus quizzes are extras for additional information, but may not yield incentives.">
                <HelpIcon />
              </Tooltip>
            )}
          </Stack>
        }
      />
      <CardContent>
        <QuizzesPanel quizzes={quizzes} showTabs={hasBonus} {...rest} />
      </CardContent>
    </Card>
  );
};

export default Quizzes;
