import React, { useState, useEffect } from "react";
import { Snackbar, Alert, Link } from "@mui/material";
import {
  Route,
  Switch,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import Div100vh from "react-div-100vh";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { QuizController } from "./components/Quiz";
import { MakeDynamicQuiz } from "./components/MakeDynamicQuiz";
import { PublicHomeController } from "./components/PublicHome";
import DeviceTest from "./components/DeviceTest";
import LoginController from "./components/LoginController";
import Terms from "./components/Terms";
import NewUserWelcome from "./components/NewUserWelcome";
import WelcomeScreen from "./components/Quiz/WelcomeScreen";
import Deactivated from "./components/Deactivated";
import isIE from "./utils/isIE";
import AppContainer from "./components/AppContainer";
import NotOnRoster from "./components/NotOnRoster";
import PilotAcceptInvite from "./components/PilotAcceptInvite";
import PilotOffer001 from "./components/PilotOffer001";
import HomePageCTA from "./components/HomePageCTA";
import Individual from "./components/Individual";
import Togo from "./components/Togo";

const IE_WARNING_DATE = "IE_WARNING_DATE";

const lastTimeIEWarningShown = localStorage.getItem(IE_WARNING_DATE);

const showIEWarning =
  (!lastTimeIEWarningShown ||
    moment().diff(moment(lastTimeIEWarningShown), "days") > 1) &&
  isIE;

const RedirectQuizzesToQuiz = () => {
  const { quizId } = useParams();
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const newPath = `/quiz/${quizId}${location.search}`;
    history.push(newPath);
  }, [quizId, location.search, history]);

  return null; // Render nothing, just perform the redirect
};

const AppRouter = ({ endScreen }) => {
  const [showIE, setShowIE] = useState(showIEWarning);

  const location = useLocation();
  const history = useHistory();
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  const handleSnackbarClose = () => {
    setShowIE(false);
    localStorage.setItem(IE_WARNING_DATE, moment());
  };

  // Example: Redirect from '/old-quiz-path' to '/quiz' while preserving params
  if (location.pathname === "/old-quiz-path") {
    history.push(`/quiz${location.search}`);
  }

  return (
    <Div100vh>
      <Snackbar
        open={showIE}
        autoHideDuration={12000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity="info"
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
        >
          Internet Explorer is no longer supported by Microsoft, and the best
          experience with Quizzify is on more modern browsers such as{" "}
          <Link
            href="https://www.microsoft.com/en-us/edge?form=MA13FJ"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white" }}
          >
            Edge
          </Link>
          ,{" "}
          <Link
            href="https://support.apple.com/en-us/HT204416"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white" }}
          >
            Safari
          </Link>
          ,{" "}
          <Link
            href="https://www.mozilla.org/en-US/exp/firefox/new/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white" }}
          >
            Firefox
          </Link>
          ,{" "}
          <Link
            href="https://brave.com/download/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white" }}
          >
            Brave
          </Link>{" "}
          or{" "}
          <Link
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white" }}
          >
            Chrome
          </Link>
          . If you still need to use Internet Explorer and experience problems
          please contact us at{" "}
          <Link
            href="mailto:support@quizzify.zendesk.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: "white" }}
          >
            support@quizzify.zendesk.com
          </Link>{" "}
          and we will help if we possibly can.
        </Alert>
      </Snackbar>
      <AppContainer>
        <Switch>
          <Route path="/pilot/accept-invite">
            <PilotAcceptInvite />
          </Route>
          <Route path="/pilot/offer">
            <PilotOffer001 />
          </Route>
          <Route path="/individual-subscription/post-payment">
            <Individual />
          </Route>
          <Route path="/quiz/:quizId/welcome">
            <WelcomeScreen />
          </Route>

          <Route path="/quiz/:quizId/:lang">
            <QuizController endScreen={endScreen} />
          </Route>

          <Route path="/quiz/:quizId">
            <QuizController endScreen={endScreen} />
          </Route>
          <Route path="/quiz/">
            <QuizController endScreen={endScreen} />
          </Route>

          <Route path="/quizzes/:quizId">
            <RedirectQuizzesToQuiz />
          </Route>
          <Route path="/home-page-cta">
            <HomePageCTA />
          </Route>
          <Route path="/dynamic/">
            <QuizController endScreen={endScreen} />
          </Route>
          <Route path="/get-dynamic/">
            <MakeDynamicQuiz />
          </Route>
          <Route path="/login">
            <LoginController />
          </Route>
          <Route path="/utils/device">
            <DeviceTest />
          </Route>
          <Route path="/terms/:lang">
            <Terms />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/:companyId/togo-download">
            <PublicHomeController />
          </Route>
          <Route path="/:companyId/er">
            <Togo />
          </Route>
          <Route path="/:companyId/welcome">
            <NewUserWelcome />
          </Route>
          <Route path="/deactivated">
            <Deactivated />
          </Route>
          <Route path="/not-on-roster">
            <NotOnRoster />
          </Route>
          <Route path="/:companyId">
            <PublicHomeController />
          </Route>
        </Switch>
      </AppContainer>
    </Div100vh>
  );
};

export default AppRouter;
