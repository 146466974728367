import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GetAppIcon from "@mui/icons-material/GetApp";
import MobileDetect from "mobile-detect";

const Q2GoButton = ({
  companyId,
  togoLink,
  needsLogin,
  setAppStoreDownloadOpen,
}) => {
  const md = new MobileDetect(window.navigator.userAgent);

  const isIphone = md.is("iPhone");
  const isAndroid = md.is("Android") || md.os() === "AndroidOS";
  const isMobile = isIphone || isAndroid;

  const togoButtonParams = (() => {
    const params = {
      target: needsLogin ? "_self" : "_blank",
      rel: "noopener noreferrer",
    };
    if (isMobile) {
      if (needsLogin) {
        params.href = `/login?company_id=${companyId}&redirect_to=/${companyId}/togo-download`;
      } else {
        params.onClick = () => setAppStoreDownloadOpen(true);
      }
    } else {
      params.href = needsLogin
        ? `/login?company_id=${companyId}&redirect_to_togo=true`
        : togoLink;
    }

    return params;
  })();

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          avatar={<ExitToAppIcon />}
          title={
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
              Take Quizzify With You!
            </Typography>
          }
          action={
            <IconButton {...togoButtonParams} color="primary">
              <GetAppIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Typography gutterBottom>
            <strong>Quizzify2Go</strong>, our companion app, is designed for
            your phone, so you can have the right information when you or your
            loved ones get care.
          </Typography>
          <Typography>
            Learn how you can save money on emergency visits, or see key
            questions for your doctor or dentist.
          </Typography>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Button
              {...togoButtonParams}
              sx={{
                display: { xs: "none", sm: "none", md: "inherit" },
              }}
            >
              <img
                alt="Quizzify2Go Preview"
                src="/img/2go-preview.png"
                style={{ maxWidth: "100%", maxHeight: 425 }}
              />
            </Button>
            <Button
              {...togoButtonParams}
              variant="contained"
              color="secondary"
              startIcon={<GetAppIcon />}
            >
              Get Quizzify2Go
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default Q2GoButton;
