import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Stack,
  CardActions,
  Button,
} from "@mui/material";
import AddressDialogue from "./AddressDialogue";

const GetTheSticker = () => {
  const [showAddressDialogue, setShowAddressDialogue] = useState(false);

  return (
    <>
      <AddressDialogue
        open={showAddressDialogue}
        handleClose={() => setShowAddressDialogue(false)}
      />
      <Card>
        <CardHeader title="Get the Sticker" />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
            <Typography>
              We can mail you the sticker which fits in your wallet. Just print,
              sign and stick on your Financial Consent at the ER.
            </Typography>
            <img
              src="/img/consent-sticker.png"
              width="750"
              style={{ maxWidth: "100%" }}
              alt="Consent Sticker"
            />
          </Stack>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowAddressDialogue(true)}
          >
            Get it by Mail
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default GetTheSticker;
